<template>
  <div class="wrap">
    <div class="banner_img">
      <img @click="scrollToBottom" class="img" src="@/assets/img/psychological/5.png" alt="" />
    </div>
    <div class="item_one">
      <div class="center">
        <div class="til">
          <div class="til_top"> 01 </div>
          <div class="tils"> <img style="margin-right: 40px;" src="@/assets/img/psychological/20.png" alt=""> 行业紧缺人才
            就业前景好<img style="margin-left: 40px;" src="@/assets/img/psychological/6.png" alt="">
          </div>
        </div>
        <div class="one_item_list">
          <div class="one_item">
            <div class="t_img">
              <img src="@/assets/img/psychological/15.png" alt="">
            </div>
            <div class="con">高额时薪</div>
            <p>考心理咨询师证书</p>
            <p>时薪</p>
            <p>150/h起</p>
            <div @click="scrollToBottom" class="t_btn">了解详情</div>

          </div>
          <div class="one_item">
            <div class="t_img">
              <img src="@/assets/img/psychological/8.png" alt="">
            </div>
            <div class="con">时间自由</div>
            <p>工作时间自由 </p>
            <p>工作休闲</p>
            <p>自己做主</p>
            <div @click="scrollToBottom" class="t_btn">了解详情</div>

          </div>
          <div class="one_item">
            <div class="t_img">
              <img src="@/assets/img/psychological/9.png" alt="">
            </div>
            <div class="con">自我成长</div>
            <p>心理咨询师内容涉及 </p>
            <p>范围广</p>
            <p>实操性高</p>
            <div @click="scrollToBottom" class="t_btn">了解详情</div>

          </div>
          <div class="one_item">
            <div class="t_img">
              <img src="@/assets/img/psychological/10.png" alt="">
            </div>
            <div class="con">工作稳定</div>
            <p>工作稳定加班少 </p>
            <p>受人尊敬</p>
            <p>且发展空间大</p>
            <div @click="scrollToBottom" class="t_btn">了解详情</div>

          </div>
        </div>
      </div>
      <div style="margin-top: 100px;" class="center">
        <div class="til">
          <div class="til_top"> 02 </div>
          <div class="tils"> <img style="margin-right: 40px;" src="@/assets/img/psychological/20.png" alt="">
            心理咨询师 就业方向<img style="margin-left: 40px;" src="@/assets/img/psychological/6.png" alt="">
          </div>
        </div>
        <div class="two_item_list">
          <div class="two_item">
            <img src="@/assets/img/psychological/11.png" alt="">
            <p>心理咨询助理</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
          <div class="two_item">
            <img src="@/assets/img/psychological/12.png" alt="">
            <p>线上平台</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
          <div class="two_item">
            <img src="@/assets/img/psychological/13.png" alt="">
            <p>学校 社区等心理咨询</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
          <div class="two_item">
            <img src="@/assets/img/psychological/14.png" alt="">
            <p>大型企业心理咨询</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
          <div class="two_item">
            <img src="@/assets/img/psychological/7.png" alt="">
            <p>心理自媒体</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
          <div class="two_item">
            <img src="@/assets/img/psychological/19.png" alt="">
            <p>个人工作室</p>
            <div @click="scrollToBottom" class="t_btn">点击了解</div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <p>快速审核报名通道</p>
      <div>
        <el-input v-model="form.name" placeholder="姓名："></el-input>
        <el-input v-model="form.education" placeholder="学历："></el-input>
        <el-input v-model="form.mobile" placeholder="电话："></el-input>
        <div class="codeBox">
          <el-input v-model="form.sjToken" placeholder="请输入验证码"></el-input>
          <button type="button" class="boxbssHdBtn" :class="time > 0 ? 'notClick' : ''" @click="getPhoneCode"
            :disable="disable">
            {{ titles }}
          </button>
        </div>

        <div @click="submits()" class="a_btn">立即咨询</div>
      </div>
    </div>
    <div class="three">
      <div class="t_b">
        <img src="@/assets/img/psychological/2.png" alt="">
      </div>
      <div class="center">
        <div class="til">
          <div class="til_top"> 03 </div>
          <div class="tils"> <img style="margin-right: 40px;" src="@/assets/img/psychological/20.png" alt="">
            心理考试概括 快速了解考情<img style="margin-left: 40px;" src="@/assets/img/psychological/6.png" alt="">
          </div>
        </div>
        <div class="t_con">
          <div class="b_bag"></div>
          <div class="b_bag_top">
            <div class="b_bag_top_left">
              <div class="b_bag_top_left_til">时间安排</div>
              <div class="b_bag_top_left_con">
                <div>考试时间：2024年的5月、8月、11月 </div>
                <div>报名时间 : 最晚考前的前30天截止报名</div>
              </div>
              <div style="background: #FFB202;" class="b_bag_top_left_til">考试题型</div>
              <div class="b_bag_top_left_con">
                <div>单选250题，每题0.3分，共75分 </div>
                <div>多选50题，每题0.5分，共25分</div>
              </div>
              <div style="background: #FF5555;" class="b_bag_top_left_til">合格标准</div>
              <div class="b_bag_top_left_con">
                <div>满分100分，60分以上为合格 </div>
              </div>
            </div>
            <div class="b_bag_top_right">
              <img style="width: 438px;" src="@/assets/img/psychological/1.png" alt="">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="four">

      <div class="center">
        <div class="til">
          <div class="til_top"> 04 </div>
          <div class="tils"> <img style="margin-right: 40px;" src="@/assets/img/psychological/20.png" alt="">
            哪些人可以报名学习心理咨询师<img style="margin-left: 40px;" src="@/assets/img/psychological/6.png" alt="">
          </div>
        </div>
      </div>
      <div class="f_con">
        <div class="fc_center">
          <div style="top: -160px;left: 420px;" class="con">
            <div class="fc_center_til">想转岗者</div>
            <div class="fc_center_con">职业灵活性较高，可以在多个行业中发挥作用，如教育、企业、医疗等。</div>
          </div>
          <div style="left: 79px; top: -70px;" class="con">
            <div style="background: #FF5555; " class="fc_center_til">宝妈</div>
            <div class="fc_center_con">不仅可增加收入还有利于家庭的平衡和孩子的教育实现事业与家庭的双赢。</div>
          </div>
          <div style="left: 0px; top: 130px;" class="con">
            <div class="fc_center_til">心理爱好者</div>
            <div class="fc_center_con">不仅可以帮助他们更深入地了解心理学知识，还可以为他们提供一个将兴趣转化为职业的机会。</div>
          </div>
          <div style="right: 79px; top: -70px;" class="con">
            <div style="background: #FF5555;" class="fc_center_til">在校学生</div>
            <div class="fc_center_con">报考心理咨询师可以增加自身的竞争力，扩大就业范围，并为未来的职业发展打下基础。</div>
          </div>
          <div style="right:  0px; top: 130px;" class="con">
            <div class="fc_center_til">上班族</div>
            <div class="fc_center_con">职场上更好地处理人际关系、提升沟通能力、提高职场竞争力。</div>
          </div>
          <img src="@/assets/img/psychological/17.png" alt="">
          <div @click="scrollToBottom" class="f_btn">点击了解更多详情</div>
        </div>

      </div>
    </div>
    <div class="five">
      <div class="t_b">
        <img src="@/assets/img/psychological/3.png" alt="">
      </div>
      <div class="center">
        <div class="til">
          <div class="til_top"> 05 </div>
          <div class="tils"> <img style="margin-right: 40px;" src="@/assets/img/psychological/20.png" alt="">
            中科院心理所心理咨询师考试<img style="margin-left: 40px;" src="@/assets/img/psychological/6.png" alt="">
          </div>
        </div>
        <div class="t_con">
          <div class="b_bag"></div>

          <div class="b_bag_top">
            <div class="b_left">
              <img src="@/assets/img/psychological/16.png" alt="">
            </div>
            <div class="b_right">
              <div class="b_right_til">证书简介</div>
              <div class="b_right_con">
                中科院心理所心理咨询师证书是由中科院心理研究所认证颁发。中科院心理研究所是国家心理科研教学机构，也是中国心理学会和中国心理咨询师协会筹委会的挂靠单位，拥有长期的心理学会学科的科研积累和雄厚的教学资源。
              </div>
              <div class="b_right_bot">
                <div style="border: 1px solid #1764E6; color: #1764E6;">官方可查</div>
                <div style="border: 1px solid #FFB202; color: #FFB202;">全国通用</div>
                <div>高含金量</div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="six">
      <div class="s_til">心理咨询师培训报名</div>
      <div class="s_con">名师全程精讲、专业辅导团队全程一站式服务</div>
      <div class="s_form">
        <el-input v-model="form.names" placeholder="请输入您的姓名："></el-input>
        <el-input v-model="form.mobiles" placeholder="请输入您的手机号：">
        </el-input>
        <div class="codeBox">
          <el-input v-model="form.sjTokens" placeholder="请输入验证码"></el-input>
          <button type="button" class="boxbssHdBtn" :class="timek > 0 ? 'notClick' : ''" @click="getPhoneCodek"
            :disable="disable">
            {{ titlesk }}
          </button>
        </div>
        <el-cascader v-model="form.selectedOptions" placeholder="请选择地区" :options="options" :show-all-levels="false" />

        <!-- <el-select filterable v-model="form.address" placeholder="所在城市："> -->
        <!-- <el-option v-for="(item, index) in province" :key="index" :label="item.areaName" :value="item.areaName">
          </el-option> -->
        <!-- </el-select> -->
        <div @click="submits(1)" class="a_btn">提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
import { registerStudentJXU, getAreaFuList, loginSmsCode } from "@/api/login";
import {
  provinceAndCityData, pcTextArr,
  provinceAndCityDataPlus, regionDataPlus,
  CodeToText, TextToCode
} from 'element-china-area-data'
import {

  addEnrollUserConsultation,
} from "@/api/home";
export default {
  data() {
    return {
      time: 0,
      timek: 0,
      titlesk: "获取验证码",
      titles: "获取验证码",
      form: {
        mobile: "",
        mobiles: "",
        sjTokens: "",
        sjToken: "",
        address: "",
        selectedOptions: []
      },
      moreActivityBox: null,
      leftBox: null,
      id: null,
      detail: {},
      province: [],
      disable: false,
      navInfo: 0,
      activityList: [],
      // pcTextArr,
      options: pcTextArr
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  mounted() {
    this.options = pcTextArr
    console.log(this.options, 'this.options');
  },
  methods: {
    getDetail() {
      let data = {
        id: this.id,
      };
    },
    scrollToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    },

    // 注册验证码
    getPhoneCode() {
      if (this.time > 0) {
        return;
      }
      if (!/^[1]([3-9])[0-9]{9}$/.test(this.form.mobile)) {
        this.$message.error("手机号格式不正确");
        return;
      }
      loginSmsCode(this.form.mobile, "confirmToken").then((res) => {
        if (res.code == 0) {
          this.time = 60;
          this.$message.success(res.msg);
          var wws = setInterval(() => {
            if (this.time < 1) {
              this.titles = "获取验证码";
              this.disable = false;
              clearInterval(wws);
            } else {
              this.titles = this.time-- + "秒后重发";
              this.disable = true;
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getPhoneCodek() {
      if (this.timek > 0) {
        return;
      }
      if (!/^[1]([3-9])[0-9]{9}$/.test(this.form.mobiles)) {
        this.$message.error("手机号格式不正确");
        return;
      }
      loginSmsCode(this.form.mobiles, "confirmToken").then((res) => {
        if (res.code == 0) {
          this.timek = 60;
          this.$message.success(res.msg);
          var wws = setInterval(() => {
            if (this.timek < 1) {
              this.titlesk = "获取验证码";
              this.disable = false;
              clearInterval(wws);
            } else {
              this.titlesk = this.timek-- + "秒后重发";
              this.disable = true;
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async submits(i) {

      if (i == 1) {
        const res = await addEnrollUserConsultation({
          studentName: this.form.names,
          mobile: this.form.mobiles,
          mobileToken: this.form.sjTokens,
          areaCity: this.form.selectedOptions && this.form.selectedOptions.length > 0 ? this.form.selectedOptions[1] : undefined,
          projectType: "6",
          intentionMajor: '心理咨询师',

          projectId: 0
        });
        if (res.code === 0) {
          this.$message.success(res.msg);

        } else {
          this.$message.error(res.msg);
        }
      } else {
        const res = await addEnrollUserConsultation({
          studentName: this.form.name,
          mobile: this.form.mobile,
          mobileToken: this.form.sjToken,
          education: this.form.education ? this.form.education : undefined,
          intentionMajor: '心理咨询师',
          projectType: "6",
          projectId: 0
        });
        if (res.code === 0) {
          this.$message.success(res.msg);

        } else {
          this.$message.error(res.msg);
        }
      }


    },
  },

};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  background: #fff;

  .banner_img {
    // width: 100%;
    // height: 664px;

    .img {
      width: 100%;
      // height: 664px;

      margin: auto;
    }
  }

  .item_one {
    width: 100%;
    background-image: url("~@/assets/img/psychological/4.png");
    background-size: 100% 100%;
    // height: 1351px;

  }
}

.center {
  width: 1100px;
  margin: 0 auto;
  padding-bottom: 20px;

  .til {

    font-family: Source Han Sans CN;
    font-weight: 800;
    font-size: 45px;
    color: #000000;
    // display: flex;
    position: relative;

    .til_top {
      position: relative;
      display: flex;
      justify-content: center;
      font-family: SourceHanSansSC;
      font-weight: 800;
      font-size: 67px;
      color: #166DE7;
      background: linear-gradient(0deg, #FFFBFC 0%, #9BC3FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      bottom: -40px;
      z-index: 99;
    }

    .tils {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      z-index: 999;
    }

    img {
      width: 100px;
      height: 40px;
    }
  }

  .one_item_list {
    display: flex;
    justify-content: space-between;

    .one_item {
      width: 257px;
      height: 290px;
      background: #FFFFFF;
      box-shadow: 0px 3px 4px 0px rgba(187, 211, 255, 0.86);
      border-radius: 30px;
      margin-top: 100px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .t_img {
        width: 97px;
        position: absolute;
        top: -50px;


      }

      .con {
        width: 100%;
        height: 57px;
        line-height: 57px;
        background: #185EE5;
        text-align: center;
        font-family: SourceHanSansSC;
        font-weight: 400;
        font-size: 35px;
        color: #FFFFFF;
        margin-bottom: 30px;
        margin-top: 55px;
      }

      p {

        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 21px;
        color: #221815;
        line-height: 34px;
      }

      .t_btn {
        position: absolute;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 23px;
        color: #FFFFFF;
        line-height: 44px;
        width: 179px;
        height: 44px;
        background: linear-gradient(0deg, #185FE5, #0F99EC);
        box-shadow: 0px 5px 3px 0px #BBD3FF;
        border-radius: 22px;
        border: 3px solid #FFFFFF;
        margin: 0 auto;
        bottom: -20px;
        cursor: pointer;
      }
    }
  }

  .two_item_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-top: 36px;

    .two_item {
      display: flex;

      flex-direction: column;
      align-items: center;
      position: relative;
      width: 340px;
      height: 212px;
      background: linear-gradient(0deg, #1764E6, #0F99EC);
      box-shadow: 0px 3px 16px 0px rgba(187, 211, 255, 0.86);
      border: 4px solid #FFFFFF;
      margin-top: 140px;

      img {
        position: absolute;
        width: 330px;
        top: -120px;
        display: block;
      }

      p {
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 27px;
        color: #FFFFFF;
        line-height: 41px;
        margin-top: 90px;
      }

      .t_btn {
        width: 159px;
        height: 37px;
        background: #FF5555;
        border-radius: 18px;
        border: 2px solid #FFFFFF;
        text-align: center;
        cursor: pointer;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 23px;
        color: #FFFFFF;
        margin-top: 20px;
      }
    }
  }

}

.apply {
  width: 100%;
  height: 276px;
  background: #1A50E3;
  padding-top: 70px;

  p {

    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 35px;
    color: #FFFFFF;
    text-align: center;
  }

  >div {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 36px;

    .a_btn {
      width: 175px;
      height: 48px;
      background: #FF5555;
      border-radius: 24px;
      border: 2px solid #FFFFFF;
      cursor: pointer;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep .el-input {
    width: 239px;
    height: 53px;
  }

  ::v-deep .el-input__inner {

    width: 239px;
    height: 53px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    background-color: transparent;

    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
  }
}

.three {
  position: relative;
  padding-bottom: 40px;

  .t_b {
    position: absolute;
    right: 0;
    top: 60px;
    width: 360px;
  }

  .t_con {
    width: 1075px;
    height: 548px;
    position: relative;
    margin: 0 auto;
    margin-top: 50px;


    .b_bag {
      position: absolute;
      width: 1049px;
      height: 522px;
      background: #1764E6;
      border-radius: 20px;
      left: 25px;
    }

    .b_bag_top {
      position: absolute;
      width: 1049px;
      height: 522px;
      background: #FFFFFF;
      box-shadow: 0px 1px 9px 0px rgba(148, 215, 255, 0.3);
      border-radius: 20px;
      top: 25px;
      padding: 50px;
      display: flex;
      justify-content: space-between;

      .b_bag_top_left {
        .b_bag_top_left_til {
          width: 230px;
          height: 54px;
          line-height: 54px;
          background: #1764E6;
          border-radius: 0px 50px 0px 0px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 32px;
          color: #FFFFFF;
          padding-left: 42px;
        }

        .b_bag_top_left_con {
          width: 360px;
          // height: 58px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 21px;
          color: #161616;
          line-height: 37px;
          margin-left: 38px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .b_bag_top_right {}
    }
  }
}

.four {

  background: #F3F8FE;
  padding-bottom: 60px;

  .f_con {
    position: relative;
    width: 1100px;
    margin: 0 auto;

    .fc_center {
      width: 380px;
      margin: 0 auto;
      margin-top: 200px;

      .con {

        position: absolute;
        width: 270px;
        // height: 145px;
        padding: 0 20px;
        background: #FFFFFF;
        box-shadow: 0px 1px 9px 0px rgba(148, 215, 255, 0.3);
        border-radius: 20px;
        padding-bottom: 10px;

        .fc_center_til {
          width: 120px;
          height: 42px;
          background: #1764E6;
          border-radius: 0 0 10px 10px;
          text-align: center;
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          line-height: 42px;
        }

        .fc_center_con {

          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 19px;
          color: #191919;
          line-height: 27px;
          margin-top: 10px;
        }
      }

      .f_btn {
        width: 497px;
        height: 63px;
        background: #FF5555;
        box-shadow: 0px 3px 16px 0px rgba(186, 187, 188, 0.86);
        border-radius: 32px;
        border: 4px solid #FFFFFF;
        font-family: SourceHanSansSC;
        font-weight: 400;
        font-size: 29px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

.five {
  position: relative;
  // padding-bottom: 56px;

  .t_b {
    position: absolute;
    left: 0;
    top: 60px;
    width: 326px;
  }

  .t_con {
    width: 1075px;
    height: 548px;
    position: relative;
    margin: 0 auto;
    margin-top: 50px;


    .b_bag {
      position: absolute;
      width: 1049px;
      height: 522px;
      background: #1764E6;
      border-radius: 20px;

    }

    .b_bag_top {
      position: absolute;
      width: 1049px;
      height: 522px;
      background: #FFFFFF;
      box-shadow: 0px 1px 9px 0px rgba(148, 215, 255, 0.3);
      border-radius: 20px;
      left: 25px;
      top: 25px;
      padding: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .b_left {
        width: 456px;
      }

      .b_right {
        width: 464px;

        .b_right_til {
          background: #1764E6;
          width: 229px;
          height: 64px;
          line-height: 64px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-size: 36px;
          color: #FFFFFF;
          padding-left: 40px;
          border-radius: 0 10px 10px 0;
        }

        .b_right_con {

          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 24px;
          color: #3E3A39;
          line-height: 41px;
          margin-top: 20px;
        }

        .b_right_bot {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          >div {
            width: 151px;
            height: 46px;
            line-height: 46px;
            background: #FFFFFF;
            border: 1px solid #FF5555;

            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 24px;
            color: #FF5555;
            text-align: center;
          }
        }
      }
    }
  }
}

.six {
  width: 100%;
  min-height: 486px;
  background-image: url("~@/assets/img/psychological/18.png");
  background-size: 100% 100%;
  padding-top: 92px;

  .s_til {

    font-family: SourceHanSansSC;
    font-weight: bold;
    font-size: 69px;
    color: #FBEF00;
    text-align: center;
  }

  .s_con {
    width: 655px;
    height: 56px;
    border-radius: 28px;
    border: 2px solid #FFFFFF;

    font-family: SourceHanSansSC;
    font-weight: 400;
    font-size: 27px;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .s_form {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px;

    .a_btn {
      width: 230px;
      height: 50px;
      background: #FFD18D;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: SourceHanSansSC;
      font-weight: bold;
      font-size: 29px;
      color: #FF3838;
      cursor: pointer;
      // margin-top: 30px;
    }

    .codeBox {
      width: 230px;
      height: 50px;
      position: relative;

      .boxbssHdBtn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 90px;
        height: 50px;
        background: #FCD172;
        border-radius: 5px;
        cursor: pointer;
        flex-shrink: 0;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        outline: none;
        text-align: center;
        border: none;
      }
    }

    ::v-deep .el-input {
      width: 230px;
      height: 50px;
      // margin-top: 30px;
      // margin-right: 10px;
    }

    ::v-deep .el-input__inner {

      width: 230px;
      height: 50px;
      background: #FFFFFF;
      border-radius: 10px;


      font-family: SourceHanSansSC;
      font-weight: 400;
      font-size: 18px;
      color: #221815;
    }
  }
}

.apply {

  .codeBox {
    width: 239px;
    height: 53px;
    position: relative;

    .boxbssHdBtn {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 90px;
      height: 53px;
      background: #FCD172;
      border-radius: 5px;
      cursor: pointer;
      flex-shrink: 0;
      line-height: 50px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      outline: none;
      text-align: center;
      border: none;
    }
  }
}

.codeBox {
  width: 230px;
  height: 50px;
  position: relative;

  .boxbssHdBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 50px;
    background: #FCD172;
    border-radius: 5px;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    outline: none;
    text-align: center;
    border: none;
  }
}

img {
  width: 100%;
}
</style>
